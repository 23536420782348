<template>
    <section>
        <label class="caption">{{ label }}</label>
        <v-text-field 
            outlined 
            dense 
            :value="value"
            :readonly="readonly"
            :placeholder="placeholder"
            class="general-custom-field mb-1"
            @input="$emit('update:value', $event)"
            hide-details="auto"/>
    </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        readonly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
    },
})
</script>